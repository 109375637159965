.introHeader {
    display: flex;
    font-family: "Lora", serif;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-bottom: 10rem;
}


.imageContainerHeader {
    width: 80%;
    height: 25rem;
    position: relative;
    border-radius: 2rem;
}

.introHeader h4 {
    width: 45%;
    font-size: 3.2rem;
    font-family: 'Playfair Display', sans-serif;
    line-height: 1.2;
    letter-spacing: .8px;
    font-weight: 400;
    text-align: center;
    padding: 0;
}
@media only screen and (max-width: 1100px) {
    .introHeader h4 {
        width: 60%;

    }
  }
@media only screen and (max-width: 900px) {
    .introHeader h4 {
        width: 70%;
    }
  }
  @media only screen and (max-width: 600px) {
    .introHeader h4 {
        width: 70%;
    }
  }

.imageContainerHeader img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;
    opacity: .6;
}

.introHeader p {
    width: 80%;
    font-size: 2rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    opacity: .8;
    margin: 4rem auto;
    color: var(--color-secondary-white);
}
.coverList {
    height: auto;
    width: auto;
    margin: 10rem auto;
}
.coverContainer {
    width: 100%;
    height: 35rem;
    position: relative;
    margin: 10rem auto;
}


.coverContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 55% 55%;
    border-radius: 2rem;
}

.coverContainer h2 {
    position: absolute;
    top: 40%;
    transform: translateY(-40%);
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Playfair Display', serif;
    font-size: 3.2rem;
    text-align: center;
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: 1.2px;
    color: var(--color-primary-white);
    text-shadow: 2px 2px 2px var(--color-black);
    transition: .5s all ease-in-out;
}


.coverContainer p {
    position: absolute;
    top: 15rem;
    transform: translateY(-40%);
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Lora', serif;
    font-size: 1.8rem;
    opacity: 0;
    line-height: 1.2;
    transition: .5s all ease-in-out;
    text-align: center;
    color: var(--color-primary-white);
    text-shadow: 1px 1px 1px var(--color-black);
}


.analogSectionCenterLink {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 1.25px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20rem;
    text-decoration: none;
    color: var(--color-primary-white);
    text-shadow: 1px 1px 1px var(--color-black);
    font-size: 1.4rem;
    padding: 2px 1.2rem;
    line-height: 1.4;
    border: 1.5px solid var(--color-grey-blue);
    border-radius: 2rem;
    display: inline-block;

transition: background-color 0.1s ease-in-out, 
            border 0.1s ease-in-out,
            top 0.5s ease-in-out,
            opacity 0.5s ease-in-out;

    opacity: 0;
}

.analogSectionCenterLink:hover {
    background-color: rgba(255, 255, 255, .2);
}
.coverContainer:hover h2 {
    top: 15rem;
    opacity: 0;
}

.coverContainer:hover p {
    opacity: .8;
    top: 14rem;
}

.coverContainer:hover .analogSectionCenterLink {
    opacity: 1;
    top: 22rem;
}


.header {
    max-width: 100%;
    font-size: 1.4rem;
    margin: auto;
    padding: 20px 0 60px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 50px;
    
  }
  @media only screen and (max-width: 600px) {
    .header {
        padding: 0;

    }
  }
  
  .list {
    display: flex;
    gap: 2rem;
  }
  
  .list a {
    text-decoration: none;
    color: var(--color-primary-400);
    border: 0.2px solid var(--color-primary-white);
    padding: 2px 8px;
    border-radius: 2rem;
  }

  .home {
    position: absolute;
    left: 0px;
  }
  
  
  
  .list a:hover,
  .list a.active {
    color: var(--color-neon-blue);
        
  }
  
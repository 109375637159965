/* .videoContainer {
  margin-top: 10rem;
  border-radius: 3rem;
  position: relative;
  width: 100%;
  height: 80vh;
  background-color: rgba(0, 0, 0, 0.951);
  margin: auto;
  color: #f8f8f8;
  font-size: 24px;
  overflow: hidden;
  z-index: 100;
} */

.videoContainer {
  border-radius: 3rem;
  width: 100%;
  height: 80vh;
  background-color: rgba(0, 0, 0, 0.951);
  margin: auto;
  color: #f8f8f8;
  font-size: 24px;
  overflow: hidden;
  position: relative;
  transition: 1.5s ease-in-out;

}
@media only screen and (max-width: 1100px) {
  .videoContainer {
      margin-bottom: 10rem;
  }
}


.videoContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  position: absolute;
  transition: 1.5s ease-in-out;
}

.videoContainer img:hover {
  scale: 1.8;
}


.textDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  margin: 0;
  gap: 5rem;
  z-index: 100;
}

.title {
  font-family: "Playfair Display", serif;
  text-align: center;
  font-weight: 400;
  color: var(--color-primary-white);
  text-shadow: 0 0 4px var(--color-black);
  letter-spacing: -.08em; /* Adjust the value as needed */
  font-size: 12rem;
  line-height: .8;
}



.subTitle {
  font-family: "Lora", serif;
  font-size: 4rem;
  line-height: 1.2;
  text-align: center;
  margin-top: -8rem;
  color: var(--color-neon-blue);
  text-shadow: 2px 2px 2px var(--color-black);
}


.enterButton {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-primary-white);
  background-color:transparent;
  border: 1px solid white;
  padding: 4px 12px;
  text-align: center;
  z-index: 100;
  border-radius: 2rem;
  transition: .2s all ease-in-out;
  letter-spacing: 1.25px;
}

.enterButton:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, .9);
  color: black;
  border: 1px solid black;
  transform: translateY(-1px);
}

.sectionTitle {
    text-align: center;
    font-family: "Playfair Display", serif;
    font-weight: 400;
    font-size: 5.8rem;
    padding: 0;
    margin-top: 20rem;
}

.analogSection {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 900px) {
  .analogSection {
      flex-direction: column;
      height: 75vh;
  }
}
@media only screen and (max-width: 600px) {
  .analogSection {
      height: 60vh;
      margin-bottom: 40rem;
  }
}

.analogSectionSub {
  width: 25%;
  height: 80vh;
  position: relative;
  border-radius: 5px;
}
@media only screen and (max-width: 900px) {
  .analogSectionSub {
      height: 20vh;
  }
}
@media only screen and (max-width: 600px) {
  .analogSectionSub {
      width: 100px;
  }
}
.analogSectionImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;

}
@media only screen and (max-width: 900px) {
  .analogSectionImg {
      object-position: 65% 65%;
  }
}

.digitalSectionImgRight {
    object-position: 40%;
}

.analogSectionTitle {
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  left: 45%;
  height: auto;
  width: 1em;
  font-size: 5rem;
  font-family: "Lora", sans-serif;
  text-shadow: 2px 2px 2px var(--color-black);
  font-weight: 400;
  line-height: 1.2;
  z-index: 5;
  color: var(--color-neon-blue);
}
@media only screen and (max-width: 900px) {
  .analogSectionTitle {
      opacity: 0;
  }
}

.digitalSectionTitle {
    color: var(--color-primary-white);
    text-shadow: 2px 2px 2px var(--color-neon-blue);
}

.letterSpan {
  font-family: "Playfair Display", serif;
  font-size: 5.8rem;
  display: block;
  text-align: center;
}

.analogSectionCenter {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 30%;
    font-size: 2.2rem;
    text-align: center;
    line-height: 1.4;
    opacity: 0.8;
    margin-top: 0;
    padding: 2rem;
    color: var(--color-secondary-white);
}

@media only screen and (max-width: 900px) {
  .analogSectionCenter {
      width: 80%;
      padding: 0;
  }
}
@media only screen and (max-width: 600px) {
  .analogSectionCenter {
      width: 100%;
  }
}
.analogSectionCenterLink {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-decoration: none;
    color: var(--color-primary-white);
    font-size: 1.4rem;
    padding: 2px 12px;
    border: 1.5px solid var(--color-grey-blue);
    border-radius: 2rem;
    margin-top: 6rem;
    display: inline-block;
    transition: .1s ease-in-out;
    letter-spacing: 1.25px;
}
@media only screen and (max-width: 900px) {
  .analogSectionCenterLink {
    margin: 1rem;

  }
}
@media only screen and (max-width: 600px) {
  .analogSectionCenterLink {
    margin-bottom: 4rem;

  }
}

.analogSectionCenterLink:hover {
    color: black;
    background-color: var(--color-flat-blue);
    border: 1px solid var(--color-primary-white);
    transform: translateY(2px) scale(0.995);
}
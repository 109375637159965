@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');


@font-face {
  font-family: Magentha;
  src: url("./assets/fonts/magentha/MagenthaSummer-Regular.otf") format("opentype"), url("./assets/fonts/magentha/MagenthaSummer-Regular.ttf") format("truetype");
}


* {
  box-sizing: border-box;
}

:root {
  font-family: "Playfair Display", serif;
  font-size: 10px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --color-black: #0C0C0C;
  --color-primary-white: #F8F8F8;
  --color-secondary-white: rgba(248, 248, 248, .8);
  --color-grey: #7D7D7D;
  --color-grey-blue: #658E9A;
  --color-flat-blue: #74A6A6;
  --color-neon-blue: #7ECCCC;
}

@media only screen and (max-width: 1250px) {
  :root {
    font-size: 54.5%;
  }
}

@media only screen and (max-width: 1100px) {
  :root {
    font-size: 50%;
  }
}

@media only screen and (max-width: 1000px) {
  :root {
    font-size: 42.5%;
  }
}
@media only screen and (max-width: 900px) {
  :root {
    font-size: 34.5%;
  }
}




body {
  font-family: Lora, serif;
  font-weight: 400;
  background-color: var(--color-black);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  z-index: -1;
  color: #fff;
  width: 80%;
  margin: auto;
  scroll-behavior: smooth;
  font-size: 10px;

}

body::before {
  content: "";
  position: fixed;
  left: -10rem;
  z-index: 9999;
  top: -10rem;
  width: calc(100% + 20rem);
  height: calc(100% + 20rem);
  background-size: 100%;
  background-repeat: no-repeat;
  opacity: 0.15;
  pointer-events: none;
  animation: noise 2s steps(2) infinite;
  z-index: -10;
}
p.loginText {
  position: relative;
  margin-top: 10rem;
  text-align: center;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

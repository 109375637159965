footer {
    height: auto;
    margin-top: 20rem;
    margin: auto;

    padding: 0;
    height: 40vh;
}

.footerContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 20rem;

}
@media only screen and (max-width: 900px) {
    .footerContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
  }
.footerContainer a {
    text-decoration: none;
    color: var(--color-neon-blue);
}
.footerContainer a:hover {
    text-decoration: underline;
}
.footerContainerCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8rem;
    
}

.footerContainerCenter h4 {
    font-size: 2.2rem;
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    margin: auto;
    color: var(--color-secondary-white);
}

.footerContainerCenter p {
    font-size: 3rem;
    font-family: 'Lora', serif;
    font-weight: 400;
    border: 1px solid grey;
    padding: 19px 23px;
    border-radius: 10px;
    margin: auto;
}
@media only screen and (max-width: 600px) {
    .footerContainerCenter p {
      font-size: 2.2rem;
      padding: 12px 14px;

    }
  }

.footerContainerCenterChild {
    display: flex;
    gap: 2rem;
    margin-bottom: 10px;
}



.footerContainerCenterChild a {
    text-decoration: none;
    color: var(--color-primary-white);
}

.footerContainerCenterChild a:hover {
    text-decoration: underline;

}

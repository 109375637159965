.introHeader {
    display: flex;
    font-family: "Lora", serif;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-bottom: 10rem;
}


.imageContainerHeader {
    width: 90%;
    height: 30rem;
    position: relative;
    border-radius: 2rem;
}

.introHeader h4 {
    width: 45%;
    font-size: 5.2rem;
    font-family: 'Playfair Display', sans-serif;
    line-height: 1.2;
    letter-spacing: .8px;
    font-weight: 400;
    text-align: center;
    padding: 0;
}
@media only screen and (max-width: 1100px) {
    .introHeader h4 {
        width: 60%;

    }
  }
@media only screen and (max-width: 900px) {
    .introHeader h4 {
        width: 70%;
    }
  }
  @media only screen and (max-width: 600px) {
    .introHeader h4 {
        width: 70%;
    }
  }

.imageContainerHeader img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;
    opacity: .6;
}

.introHeader p {
    width: 80%;
    font-size: 2.2rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.8;
    font-weight: 400;
    text-align: center;
    opacity: .8;
    margin: 4rem auto;
    color: var(--color-secondary-white);
}
@media only screen and (max-width: 600px) {
    .introHeader p {
        line-height: 1.6;
        width: 80%;
        padding: 2rem;
    }
  }

.introHeader a {
    text-decoration: none;
    color: var(--color-neon-blue);
}

@media only screen and (max-width: 1100px) {
    .introHeader p {
        height: auto;
    }
  }

  @media only screen and (max-width: 900px) {
    .introHeader p {
        width: 80%;
    }
  }

.gallery {
    margin: auto;
    grid-column: full-start / full-end;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(24, 5vw);
    grid-gap: 1.5rem;
    padding: 0 1.5rem;

}
@media only screen and (max-width: 1100px) {
    .gallery {
        width: 100%;
    }
  }
  @media only screen and (max-width: 900px) {
    .gallery {
        grid-gap: .5rem;
    }
  }
  @media only screen and (max-width: 600px) {
    .gallery  {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
  }
.galleryItem {
    position: relative;
    transition: .15s all ease-in;
}
@media only screen and (max-width: 600px) {
    .galleryItem  {
        height: 40vh;
        width: 100%;
  }
}

.galleryItem:hover {
    cursor: pointer;
    transform: translateY(-2px);
    opacity: .9;
}

.galleryItem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    position: absolute;
    border-radius: 5px;
}
.galleryItem0 {
    grid-row: 1 / span 6;
    grid-column: 1 / span 6;
}
.galleryItem1 {
    grid-row: 1 / span 4;
    grid-column: 6 / span 7;
}
.galleryItem2 {
    grid-row: 5 / span 4;
    grid-column: 6 / span 7;
}
.galleryItem3 {
    grid-row: 7 / span 6;
    grid-column: 1 / span 6;
}
.galleryItem4 {
    grid-row: 9 / span 8;
    grid-column: 6 / span 7;
}
.galleryItem5 {
    grid-row: 13 / span 6;
    grid-column: 1 / span 6;
}
.galleryItem6 {
    grid-row: 17 / span 7;
    grid-column: 6 / span 7;
}
.galleryItem7 {
    grid-row: 19 / span 5;
    grid-column: 1 / span 6;
}




.seeMore {
    text-align: center;
    font-family: "Playfair Display", sans-serif;
    font-size: 3.2rem;
    font-weight: 400;
    padding: 8rem;
}


.carousel {
    width: 100%;
    height: auto;
    margin: auto;
    position: relative;
}

.coverContainer {
    width: 95%;
    height: 55rem;
    position: relative;
    margin: auto;
}


.coverContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 55% 55%;
    border-radius: 2rem;
}
.coverContainerTitleDiv {
    position: absolute;
    top: 70%;
    width: 100%;
    height: 30%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.4);
}


.coverContainerTitleDiv h2 {
    font-size: 1.8rem;
    font-family: 'Lora', sans-serif;
    font-weight: 400;
    padding: 0;
    margin: 0;
    text-align: center;
    color: var(--color-primary-white);
    text-shadow: 1px 1px 1px var(--color-black);
}

.coverContainerTitleDiv p {
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
    color: var(--color-secondary-white);
}



.analogSectionCenterLink {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-decoration: none;
    letter-spacing: 1.25px;
    line-height: 1.4;
    color: var(--color-primary-white);
    text-shadow: 1px 1px 1px var(--color-black);
    font-size: 1.4rem;
    padding: 2px 12px;
    border: 1.5px solid var(--color-grey-blue);
    border-radius: 2rem;
    display: inline-block;
transition: background-color 0.1s ease-in-out, 
            border 0.1s ease-in-out,
            top 0.5s ease-in-out,
            opacity 0.5s ease-in-out;
            

}

.analogSectionCenterLink:hover {
    background-color: rgba(255, 255, 255, .2);
}


.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);  
    z-index: 999; /* Ensure the modal is above other content */
  }
  
  /* Modal Content */
  .modalContent {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Shadow effect */
  }
  
  /* Modal Image */
  .modalImage {
    width: 95vw;
    height: 90vh;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    object-fit: contain;
  }
  @media only screen and (max-width: 1100px) {
    .modalImage {
      width: 85vw;
      height: 65vh;
    }
  }
  @media only screen and (max-width: 600px) {
    .modalImage {
      top: 50px;
    }
  }
  
  /* Close Button */
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .closeButton:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
.mainAboutDiv {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imageContainer {
    height: 60rem;
    width: 50rem;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

  

.mainAboutDiv h1 {
    text-align: center;
    font-size: 8rem;
    font-family: "Playfair Display", serif;
    color: var(--color-primary-white);
    width: 50rem;
    line-height: 1.2;
    margin: auto;
    margin-bottom: -4rem;
    z-index: 1000;
    margin-top: 0;

}

.mainAboutDiv p {
    padding: 6rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    margin: auto;
    font-size: 2.2rem;
    color: var(--color-secondary-white);
    width: 85%;
    line-height: 2.2;
}
@media only screen and (max-width: 900px) {
    .mainAboutDiv p {
        width: 95%;

    }
  }
  @media only screen and (max-width: 600px) {
    .mainAboutDiv p {
        width: 100%;
    }
  }

.image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2rem;
}

.workDiv {
    margin: auto;
    font-family: "Playfair Display", serif;
    height: auto;
    width: 100%;
    padding: 10rem 0;
    display: flex;
    justify-content: space-between;
    align-items: end;
    line-height: 2;
}

.workDiv h1 {
    font-size: 6rem;
    letter-spacing: .6;
}

.workDiv div {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    font-size: 2.2rem;
    gap: 1.5rem;
}



.workDiv div a {
    padding: 2rem 4rem;
    border: 1px solid grey;
    border-radius: 1.5rem;
    text-decoration: none;
    color: var(--color-primary-white);
    font-family: "Lora", sans-serif;
}

.workDiv div a:hover {
    background-color: rgba(255, 255, 255, .2);
    cursor: pointer;
}

.workDiv div p {
    padding: 2rem 4rem;
    border: 1px solid grey;
    border-radius: 1.5rem;
    text-decoration: none;
    color: var(--color-primary-white);
}

.workDiv div p:hover {
    background-color: rgba(255, 255, 255, .2);
}

@media only screen and (max-width: 900px) {
    .workDiv {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    .workDiv div {
        width: 100%;
        display: flex;
        flex-direction: row;
        font-size: 1.8;
        justify-content: center;
        align-items: center;
    }
    .workDiv div a {
        padding: 1rem 2rem;
        border: 1px solid grey;
        border-radius: 1rem;
        text-decoration: none;
        color: var(--color-primary-white);
    }
    .workDiv div p {
        padding: 1rem 2rem;
        border: 1px solid grey;
        border-radius: 1rem;
        text-decoration: none;
        color: var(--color-primary-white);
    }
  }


  @media only screen and (max-width: 1400px) {
    .workDiv div p {
        font-size: 2rem;
    }
    .workDiv div a {
        font-size: 2rem;
    }
  }
